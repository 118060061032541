<template>
  <modal
    name="magazine-modal"
    :resizable="true"
    :height="'auto'"
    :width="'100%'"
    :scrollable="true"
  >
    <div class="row bg-secondary mt-1 ml-0">
      <div class="col-6">
        <h3 style="color: #fff">{{ item.title }}</h3>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="float-right">
          <button
            id="editPaymentButton"
            class="btn btn-sm btn-pill btn-info my-1 mx-2"
            @click="updateItem(item)"
          >
            Edytuj
          </button>
          <button
            class="btn btn-sm btn-pill btn-danger my-1 mx-2"
            @click="$emit('closeModal')"
          >
            Zamknij
          </button>
        </div>
      </div>
    </div>
    <div id="modal-content" class="row mb-5">
      <div class="col-12 p-5">
        <input
          v-model="item.title"
          type="text"
          class="form-control mt-1 mb-1"
          placeholder="Nazwa"
        />

        <div class="row">
          <div class="col-sm-12 col-md-6">
            <small>Ilość </small>
            <input
              v-model="item.quantity"
              type="text"
              class="form-control mt-1 mb-1"
              placeholder="Ilość"
            />
          </div>
          <div class="col-sm-12 col-md-6">
            <h5>Ostatnia edycja</h5>
            {{ itemDataToLocal }}
            <!-- <input
              v-model="item.lastEdit"
              type="date"
              class="form-control mt-1 mb-1"
              placeholder="ostatnia edycja"
            /> -->
          </div>
        </div>

        <small>Uwagi</small>
        <vue-editor
          class="border"
          style="height: 350px; overflow: auto"
          v-model="item.notice"
        ></vue-editor>

        <hr />
        <small style="cursor: pointer" @click="deleteItem(item)">
          Usuń płatność tylko gdy jesteś tego pewny.
        </small>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";

export default {
  props: ["item"],
  label: "",

  data() {
    return {
      imageUrl: "",
      consolelog: "test",
    };
  },
  components: {
    VueEditor
  },
  computed: {
    ...mapState(["userProfile", "magazineItemUpdated"]),
    itemDataToLocal() {
      if (this.item.lastEdit) {
        return new Date(this.item.lastEdit.seconds * 1000).toLocaleDateString();
      } else {
        return "Nie był edytowany";
      }
    },
  },

  // created() {
  //   var scripts = ["https://smtpjs.com/v3/smtp.js"];
  //   scripts.forEach((script) => {
  //     let tag = document.createElement("script");
  //     tag.setAttribute("src", script);
  //     document.head.appendChild(tag);
  //   });
  // },

  methods: {
    // send(payment) {
    //   Email.send({
    //     SecureToken: "edd158ac-b4c0-46b9-8986-d78583d5ce84",
    //     // Host: "serwer1984787.home.pl",
    //     // Username: "no-reply-klimbud@2qbs.pl",
    //     // Password: "y9tTLZp_K",
    //     To: payment.contractor.email,
    //     Cc: "biuro@klim-bud.pl",
    //     From: "no-reply-klimbud@2qbs.pl",
    //     Subject: "Przypomnienie o terminie płatności faktury " + payment.invoiceNumber,
    //     Body:
    //       "<h2> Przypominamy o nieopłaconej fakturze nr. " +
    //       payment.invoiceNumber +
    //       " </h2>" +
    //       "<div>" +
    //       "Kwota: " +
    //       payment.price +
    //       "<br/>" +
    //       "Data płatności: " +
    //       payment.date +
    //       "<br>" +
    //       "<br>" +
    //       "<small>Wiadomość została wygenerowana automatycznie, prosimy na nią nie odpowiadać.</small>" +
    //       "</div>" +
    //       "<br>" +
    //       "<hr>" +
    //       "<div>" +
    //       "<br>" +
    //       "<br>" +
    //       "<img src='https://klim-bud.pl/wp-content/uploads/2020/07/LOGO_KLIM-BUD.png'>" +
    //       "<br>" +
    //       "<br>" +
    //       "F.H.U Klim-Bud" +
    //       "<br>" +
    //       "Wąska 4" +
    //       "<br>" +
    //       "43-520 Chybie" +
    //       "<br>" +
    //       "tel. 504 041 946" +
    //       "<br>" +
    //       "<a href='mailto:biuro@klim-bud.pl'>biuro@klim-bud.pl</a> " +
    //       "<br>" +
    //       "<a href='https://klim-bud.pl' target='_blank'>www.klim-bud.pl</a>",
    //   }).then(() => alert("Przypomnienie zostało wysłane"));
    // },

    updateItem(item) {
      console.log(item);
      this.$store
        .dispatch("updateMagazineItem", {
          id: item.id,
          notice: item.notice,
          quantity: item.quantity,
          title: item.title,
        })
        .then(() => {
          let button = document.getElementById("editPaymentButton");
          button.style.backgroundColor = "#409855";
          button.style.color = "#17801b";
          button.innerText = "Element została edytowana";

          setTimeout(() => {
            let button = document.getElementById("editPaymentButton");
            button.style.backgroundColor = "#409855";
            button.style.color = "";
            button.innerText = "Element została edytowana";
            this.carUpdated = null;
          }, 200);
          setTimeout(() => {
            this.$emit("closeModal");
          }, 2000);
        });
    },

    deleteItem(item) {
      if (confirm("Czy jesteś pewien ze chcesz usunąć element?")) {
        this.$store.dispatch("deleteMagazineItem", {
          id: item.id,
        });

        setTimeout(() => {
          this.$emit("closeModal");
        }, 200);
        console.log("payment Deleted.");
      }
    },
  },
};
</script>
<style></style>
