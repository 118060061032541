<template>
  <div>
    test
    <!-- HEADER STRONY -->
    <div class="row bg-light mt-1 ml-0">
      <div class="col-6">
        <router-link to="/add-magazine-item">
          <button style="width: 10%" class="btn btn-info mt-1 mb-1 float-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
              />
              <path
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
              />
            </svg>
            <br />
            <!-- Dodaj -->
          </button>
        </router-link>
        <button @click="getData()" class="btn btn-warning ml-1 mt-1 mb-1 float-left">
          Pobierz Magazyn
        </button>
      </div>
      <!-- <div class="col-1">
        <input
          type="checkbox"
          id="payments"
          value="Nalezności"
          v-model="paymentsChecked"
        />
        <label class="ml-2 align-middle" for="payments">Naleznosci</label>
      </div>
      <div class="col-1">
        <input type="checkbox" id="costs" value="Koszty" v-model="costsChecked" />
        <label class="ml-2 align-middle" for="wydatki">Koszty</label>
      </div> -->

      <div class="col-4">
        <input
          v-model="search"
          class="form-control w-100 float-right form-control-sm mt-1 mr-3"
          type="search"
          placeholder="Wyszukaj"
          aria-label="Search"
        />
      </div>
    </div>

    <!-- TABLEA -->

    <table class="table">
      <thead>
        <tr>
          <th scope="col">Nazwa</th>
          <th scope="col">Ilość</th>
          <th scope="col">Ostatnia edycja</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" v-for="item in filteredItems" :key="item.id">
          <td>{{ item.title }}</td>
          <td>{{ item.quantity }}</td>
          <td>{{ lastEdit(item.lastEdit) }}</td>
          <!-- <td>{{new Date(this.item.lastEdit.seconds * 1000).toLocaleDateString()}}</td> -->
          <td>
            <button
              class="btn btn-sm align-middle btn-info"
              @click="openMagazineModal(item)"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-file-earmark-arrow-up"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
                />
                <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
                <path
                  fill-rule="evenodd"
                  d="M8 12a.5.5 0 0 0 .5-.5V7.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 7.707V11.5a.5.5 0 0 0 .5.5z"
                />
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <magazine-modal @closeModal="closeModal()" :item="item"></magazine-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import MagazineModal from "../components/MagazineModal";

export default {
  data() {
    return {
      //   installation: {
      //     brand: "",
      //     model: "",
      //     plate: "",
      //     vin: "",
      //     year: "",
      //     imageUrl: "",
      //   },
      //   openForm: false,
      search: "",
      item: "",
      costsChecked: false,
      paymentsChecked: false,
      phoneSearch: "",
      today: new Date("yyyy-mm-dd"),
    };
  },
  components: {
    "magazine-modal": MagazineModal,
  },

  computed: {
    ...mapState(["userProfile", "magazine"]),
    formatDate() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    // sortedPayments() {
    //   function compare(a, b) {
    //     return new Date(b.date) - new Date(a.date);
    //   }
    //   let arr = this.allPayments;

    //   return arr.sort(compare);
    // },

    // allPayments() {
    //   return this.payments.concat(this.paymentsArchive);
    // },

    filteredItems() {
      return this.magazine.filter((item) => {
        if (item.title.toUpperCase().includes(this.search.toUpperCase())) {
          return item;
        } else if (item.quantity.toUpperCase().includes(this.search.toUpperCase())) {
          return item;
        }
      });
    },
  },
  methods: {
    openMagazineModal(event) {
      console.log(event);
      this.$modal.show("magazine-modal");
      this.item = event;
      // Prevent navigating to narrower view (default vue-cal behavior).
      // e.stopPropagation();
    },
    closeModal() {
      this.$modal.hide("magazine-modal");
    },
    lastEdit(e) {
      return new Date(e.seconds * 1000).toLocaleDateString();
    },
    getData() {
      this.$store.dispatch("getMagazine");
    },
  },
};
</script>
<style>
.customer {
  background-color: rgba(0, 123, 255, 0.1);
}
.company {
  background-color: rgba(23, 162, 184, 0.1);
}
.outOfDate {
  background-color: lightpink !important;
  font-weight: 900;
}
.end {
  background-color: lightgreen !important;
}
</style>
